import React, { FC, useState, useEffect, memo } from 'react'
import { Flex } from 'rebass'
import styled from 'styled-components'
import { TYPE } from '../../Theme'
import { formattedNum } from '../../utils'
import { formatAmount } from '../../utils/formatAmount'
import { ButtonIXSSecondaryGradient } from '../ButtonStyled'
import DoubleTokenLogo from '../DoubleLogo'
import { BasicLink } from '../Link'
import { Pagination } from '../Pagination'
import { BodyRow, HeaderRow, Table } from '../Table'

const headerCells = ['Name', 'Volume', 'Price change', '24H Volume', 'TVL', '']

export interface IPoolToken {
  id: string
  name: string
  symbol: string
}

interface IPoolPair {
  id: string
  token0: IPoolToken
  token1: IPoolToken
  oneDayVolumeUSD: number
  volumeUSD: number
  trackedReserveUSD: number
  liquidityChangeUSD: number
  reserveUSD: string
}

interface BodyProps extends TableProps {
  page: number
  pageLimit: number
}

interface TableProps {
  items: IPoolPair[]
}

const Header = () => {
  return (
    <StyledHeaderRow>
      {headerCells.map((cell) => (
        <div key={cell}>{cell}</div>
      ))}
    </StyledHeaderRow>
  )
}

const Body: FC<BodyProps> = ({ items, page, pageLimit }: BodyProps) => {
  return (
    <>
      {items
        .slice((page - 1) * pageLimit, page * pageLimit)
        .map(
          ({ id, token0, token1, volumeUSD, liquidityChangeUSD, oneDayVolumeUSD, trackedReserveUSD, reserveUSD }) => (
            <StyledBodyRow as={BasicLink} to={`/pools/${id}`} key={`pool-table-${id}`}>
              <Flex alignItems="center">
                <DoubleTokenLogo size={25} a0={token0.id} a1={token1.id} />
                <TYPE.primary fontSize={14} lineHeight="30px" marginLeft="25px">
                  {token0.symbol + '/' + token1.symbol}
                </TYPE.primary>
              </Flex>
              <div>
                <TYPE.body fontSize={13} lineHeight="30px">
                  {`$${formatAmount(Number(volumeUSD), 1, 1)}`}
                </TYPE.body>
              </div>
              <div>
                <TYPE.body
                  style={{ color: liquidityChangeUSD > 0 ? 'green' : liquidityChangeUSD < 0 ? 'red' : 'green' }}
                  fontSize={13}
                  lineHeight="30px"
                >
                  {`${formatAmount(liquidityChangeUSD, 2, 2)}%`}
                </TYPE.body>
              </div>
              <div>
                <TYPE.body fontSize={13} lineHeight="30px">
                  {`$${formatAmount(oneDayVolumeUSD, 0, 0)}`}
                </TYPE.body>
              </div>
              <div>
                <TYPE.body fontSize={13} lineHeight="30px">
                  {`${formattedNum(reserveUSD || trackedReserveUSD, true)}`}
                </TYPE.body>
              </div>
              <div>
                {/* <ButtonIXSSecondaryGradient style={{ maxWidth: 80 }}> */}
                <TYPE.primary style={{ color: '#6666FF' }} fontSize={13} lineHeight="21px">
                  Details
                </TYPE.primary>
                {/* </ButtonIXSSecondaryGradient> */}
              </div>
            </StyledBodyRow>
          )
        )}
    </>
  )
}

export const PairsTable: FC<TableProps> = ({ items }: TableProps) => {
  const [page, setPage] = useState(1)
  const pageLimit = 10
  const totalPages = Math.ceil(items.length / pageLimit)

  const onPageChange = (newPage: number) => {
    setPage(newPage)
  }

  useEffect(() => {
    setPage(1)
  }, [items])

  return (
    <>
      {items.length === 0 ? (
        <NoData>No data</NoData>
      ) : (
        <Container>
          <Table body={<Body page={page} pageLimit={pageLimit} items={items} />} header={<Header />} />
          <Pagination page={page} totalPages={totalPages} onPageChange={onPageChange} />
        </Container>
      )}
    </>
  )
}

export const NoData = styled.div`
  font-weight: 600;
  color: ${({ theme: { text2 } }) => text2};
  text-align: center;
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 50px;
`

export const StyledHeaderRow = styled(HeaderRow)`
  grid-template-columns: 1.5fr repeat(5, 0.8fr);
  min-width: 1300px;
`

const StyledBodyRow = styled(BodyRow)`
  grid-template-columns: 1.5fr repeat(5, 0.8fr);
  min-width: 1300px;
  cursor: pointer;
  // :hover {
  //   border: 1px solid ${({ theme: { bg7 } }) => bg7};
  // }
  > div {
    display: flex;
    justify-content: start;
  }
`
