import styled from 'styled-components'

export const Input = styled.input`
  background-color: none;
  font-size: 20px;
  border-radius: 8px;
  height: 40px;
  outline: none;
  border: 1px solid #e6e6ff;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  ::placeholder {
    color: #b8b8cc;
  }
  color: ${({ theme, color }) => (color === 'red' ? theme.red1 : theme.text1)};
  padding: 10px 22px;
  margin-bottom: 54px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`
