import { getAddress } from '@ethersproject/address'
import { TokenList } from '@uniswap/token-lists'
import walletValidator from 'multicoin-address-validator'

import { WrappedTokenInfo } from './wrappedTokenInfo'

export function isAddress(value: any): string | false {
  if (!value) {
    return false
  }
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export type TokenAddressMap = Readonly<{
  [chainId: number]: Readonly<{ [tokenAddress: string]: { token: WrappedTokenInfo; list: TokenList } }>
}>

export function listToTokenMap(list: TokenList): TokenAddressMap {
  const map = list.tokens.reduce<TokenAddressMap>((tokenMap, tokenInfo) => {
    const token = new WrappedTokenInfo(tokenInfo, list)
    if (tokenMap[token.chainId]?.[token.address] !== undefined) {
      console.error(new Error(`Duplicate token! ${token.address}`))
      return tokenMap
    }
    return {
      ...tokenMap,
      [token.chainId]: {
        ...tokenMap[token.chainId],
        [token.address]: {
          token,
          list,
        },
      },
    }
  }, {})
  return transformMap(map)
}

export function transformMap(map2: TokenAddressMap): TokenAddressMap {
  return {
    [1]: { ...map2[1] },
    [4]: { ...map2[4] },
    [3]: { ...map2[3] },
    [42]: { ...map2[42] },
    [137]: { ...map2[137] },
    [80001]: { ...map2[80001] },
    [5]: { ...map2[5] },
  }
}
