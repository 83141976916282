import 'feather-icons'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FullWrapper, PageWrapper } from '../components'
import { ButtonGradientBorder } from '../components/ButtonStyled'
import Column from '../components/Column'
import { Input } from '../components/Input'
import { BasicLink } from '../components/Link'
import PoolCard, { Divider } from '../components/PoolCard'
import Row, { RowBetween, RowFixed } from '../components/Row'
import { useGlobalData } from '../contexts/GlobalData'
import { shouldFilterOutPair, usePairs } from '../contexts/PairData'
import { TYPE } from '../Theme'
import { Container } from '../Theme/wrappers'
import { searchPairs } from '../utils/filter'
import Header from '../components/Header'
import LocalLoader from '../components/LocalLoader'
import { LoaderThin } from '../components/LoaderThin'
import { formatAmount } from '../utils/formatAmount'
import { ReactComponent as ShowMore } from '../assets/show-more.svg'
import CryptoCard from '../components/CryptoCard'
import { ButtonDark } from '../components/ButtonStyled'
import Link from '../components/Link'
import { isMobile } from 'react-device-detect'
import { TOKEN_BLACKLIST } from '../constants'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;

  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 540px) {
    grid-template-columns: 1fr;
  }
`
export const PairTabs = {
  CRYPTO: 'Crypto',
  SECURITY: 'Security',
  ALL: 'All',
}

function AllPairsPage() {
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { totalValueLocked, totalValueLockedSecurity, totalValueLockedCrypto } = useGlobalData()
  const { secPairs = {}, cryptoPairs = {} } = usePairs()

  const [filteredSecPairs, setFilteredSecPairs] = useState({})
  const [filteredCryptoPairs, setFilteredCryptoPairs] = useState({})

  const onSearchChange = (e) => {
    const search = e.currentTarget.value
    setSearchValue(search)
    const normalizedSearch = search.trim()?.toLowerCase()
    if (normalizedSearch.length === 0) {
      setFilteredSecPairs({})
      setFilteredCryptoPairs({})
    }
    if (normalizedSearch?.length < 2) {
      return
    }
    const newSec = searchPairs(secPairs, normalizedSearch)
    const newCrypto = searchPairs(cryptoPairs, normalizedSearch)
    if (newSec) {
      setFilteredSecPairs(newSec)
    }
    if (newCrypto) {
      setFilteredCryptoPairs(newCrypto)
    }
  }

  const visibleSec = Object.keys(filteredSecPairs).length || searchValue.length ? filteredSecPairs : secPairs
  const visibleCrypto =
    Object.keys(filteredCryptoPairs).length || searchValue.length ? filteredCryptoPairs : cryptoPairs
  const showSec = Object.keys(visibleSec).length
  const showCrypto = Object.keys(visibleCrypto).length

  return (
    <>
      <Header />
      <Container width={['100%']} maxWidth={'1299px'} margin={'auto'}>
        <PageWrapper>
          {/* <Link href="https://app.ixswap.io/" target="_blank">
            <div
              style={{
                background: 'none',
                color: 'black',
                border: '1px solid #E6E6FF',
                borderRadius: '6px',
                padding: '10px',
                marginBottom: '20px',
                float: 'inline-end',
                width: '140px',
              }}
            >
              {' '}
              Launch IXSwap App
            </div>
          </Link> */}
          <FullWrapper style={{ marginTop: '70px' }}>
            {/* <Input value={searchValue} placeholder="Search" onChange={onSearchChange} /> */}

            <>
              {isMobile ? (
                <>
                  <div>
                    <TYPE.primary fontSize={30} lineHeight="44px">
                      Security pools
                    </TYPE.primary>
                    <TYPE.light style={{ marginTop: '12px', width: '100%', fontSize: '16px' }}>
                      Security Pools are decentralized financial mechanisms in the realm of cryptocurrencies designed to
                      enhance the security and stability of digital asset transactions. These pools serve as a
                      collective resource where participants contribute their funds to mitigate risks associated with
                      various crypto-related activities.
                    </TYPE.light>
                  </div>

                  <TYPE.light lineHeight="27px">Total Value locked:</TYPE.light>
                  <Column style={{ marginTop: '10px', marginRight: '46px' }}>
                    {Boolean(showSec) && (
                      <TYPE.primary color={'#6666FF'} fontSize={32}>
                        ${formatAmount(+totalValueLockedSecurity.toFixed(0))}
                      </TYPE.primary>
                    )}
                    {!showSec && (
                      <Row marginTop={3}>
                        <LoaderThin size={32} />
                      </Row>
                    )}
                  </Column>
                </>
              ) : (
                <>
                  {' '}
                  <RowBetween>
                    <TYPE.primary fontSize={40} lineHeight="44px">
                      Security pools
                    </TYPE.primary>
                    <Column style={{ marginRight: '46px' }}>
                      <TYPE.light style={{ marginTop: '40px' }} fontSize={'13px'} color={'#8F8FB2'} lineHeight="27px">
                        Total Value locked:
                      </TYPE.light>
                      {Boolean(showSec) && (
                        <TYPE.primary color={'#6666FF'} fontSize={32} lineHeight="56px">
                          ${formatAmount(+totalValueLockedSecurity.toFixed(0))}
                        </TYPE.primary>
                      )}

                      {!showSec && (
                        <Row marginTop={3}>
                          <LoaderThin size={32} />
                        </Row>
                      )}
                    </Column>
                  </RowBetween>
                  <TYPE.light style={{ marginTop: '0px', width: '55%', fontSize: '16px' }}>
                    Security Pools are decentralized financial mechanisms in the realm of cryptocurrencies designed to
                    enhance the security and stability of digital asset transactions. These pools serve as a collective
                    resource where participants contribute their funds to mitigate risks associated with various
                    crypto-related activities.
                  </TYPE.light>
                </>
              )}
              {/* Rest of the code */}

              <RowFixed style={{ marginBottom: '21px' }}>
                {/* <Column>
                  <TYPE.primary style={{ textTransform: 'uppercase' }} fontWeight={500} fontSize={18} lineHeight="27px">
                    My ETH Rewards
                  </TYPE.primary>
                  <TYPE.primary fontSize={36} lineHeight="56px">
                    0.015
                  </TYPE.primary>
                </Column> */}
              </RowFixed>
              {Boolean(showSec) && (
                <Grid>
                  {Object.keys(visibleSec)
                    .slice(0, 6)
                    .map((item, index) => (
                      <CryptoCard key={`pool-${index}-all`} item={visibleSec[item]} allPairs={visibleSec} />
                    ))}
                </Grid>
              )}

              {!showSec && (
                <Row>
                  <Column style={{ alignItems: 'center', width: '100%' }}>
                    <LocalLoader />
                  </Column>
                </Row>
              )}
              <BasicLink to={`/pools/security`} style={{ margin: '48px auto 36px' }}>
                <ButtonGradientBorder style={{ padding: '10px 16px' }}>
                  <TYPE.main color={'#6666FF'} lineHeight="20px">
                    <ShowMore style={{ marginRight: '10px', marginTop: '0px' }} /> Show all
                  </TYPE.main>
                </ButtonGradientBorder>
              </BasicLink>

              <Divider />
            </>

            <>
              {/* <TYPE.primary fontSize={36} lineHeight="56px">
                Crypto pools
              </TYPE.primary>
              <TYPE.lightText fontWeight={300} marginBottom="9px"></TYPE.lightText>

              <RowFixed style={{ marginBottom: '21px' }}>
                <Column style={{ marginRight: '46px' }}>
                  <TYPE.primary style={{ textTransform: 'uppercase' }} fontWeight={500} fontSize={18} lineHeight="27px">
                    Total Value locked:
                  </TYPE.primary>
                  {showCrypto && (
                    <TYPE.primary fontSize={36} lineHeight="56px">
                      ${formatAmount(+totalValueLockedCrypto.toFixed(0))}
                    </TYPE.primary>
                  )}

                  {!showCrypto && (
                    <Row marginTop={3}>
                      <LoaderThin size={32} />
                    </Row>
                  )}
                </Column> */}
              {/* <Column>
                  <TYPE.primary style={{ textTransform: 'uppercase' }} fontWeight={500} fontSize={18} lineHeight="27px">
                    My ETH Rewards
                  </TYPE.primary>
                  <TYPE.primary fontSize={36} lineHeight="56px">
                    0.015
                  </TYPE.primary>
                </Column> */}
              {/* </RowFixed> */}

              {isMobile ? (
                <>
                  <RowBetween style={{ marginTop: '30px' }}>
                    <TYPE.primary fontSize={30} lineHeight="44px">
                      Crypto pools
                    </TYPE.primary>
                  </RowBetween>
                  <TYPE.light style={{ marginTop: '0px', marginBottom: '15px', width: '100%', fontSize: '16px' }}>
                    Crypto Pools, also known as liquidity pools, are collective pools of digital assets held by
                    participants in the cryptocurrency ecosystem. These pools enable individuals to contribute their
                    cryptocurrencies or tokens to a shared liquidity pool, which in turn facilitates various
                    decentralized financial activities.
                  </TYPE.light>
                  <TYPE.light lineHeight="27px">Total Value locked:</TYPE.light>
                  <Column style={{ marginRight: '46px' }}>
                    {showCrypto && (
                      <TYPE.primary fontSize={36} lineHeight="56px">
                        ${formatAmount(+totalValueLockedCrypto.toFixed(0))}
                      </TYPE.primary>
                    )}

                    {!showSec && (
                      <Row marginTop={3}>
                        <LoaderThin size={32} />
                      </Row>
                    )}
                  </Column>
                </>
              ) : (
                <>
                  {' '}
                  <RowBetween style={{ marginTop: '30px' }}>
                    <TYPE.primary fontSize={40} lineHeight="44px">
                      Crypto pools
                    </TYPE.primary>
                    <Column style={{ marginRight: '46px' }}>
                      <TYPE.light style={{ marginBottom: '20px' }}>Total Value locked:</TYPE.light>
                      {showCrypto && (
                        <TYPE.primary color={'#6666FF'} fontSize={36}>
                          ${formatAmount(+totalValueLockedCrypto.toFixed(0))}
                        </TYPE.primary>
                      )}

                      {!showSec && (
                        <Row marginTop={3}>
                          <LoaderThin size={32} />
                        </Row>
                      )}
                    </Column>
                  </RowBetween>
                  <TYPE.light style={{ marginTop: '0px', marginBottom: '40px', width: '55%', fontSize: '16px' }}>
                    Crypto Pools, also known as liquidity pools, are collective pools of digital assets held by
                    participants in the cryptocurrency ecosystem. These pools enable individuals to contribute their
                    cryptocurrencies or tokens to a shared liquidity pool, which in turn facilitates various
                    decentralized financial activities.
                  </TYPE.light>
                </>
              )}

              {showCrypto && (
                <Grid>
                  {Object.keys(visibleCrypto)
                    .filter((poolId) => !shouldFilterOutPair(visibleCrypto[poolId]))
                    .slice(0, 6)
                    .map((item, index) => {
                      return (
                        <CryptoCard key={`pool-${index}-all`} item={visibleCrypto[item]} allPairs={visibleCrypto} />
                      )
                    })}
                </Grid>
              )}

              {!showCrypto && (
                <Row>
                  <Column style={{ alignItems: 'center', width: '100%' }}>
                    <LocalLoader />
                  </Column>
                </Row>
              )}
              <BasicLink to={`/pools/crypto`} style={{ margin: '48px auto 36px' }}>
                <ButtonGradientBorder style={{ padding: '10px 16px' }}>
                  <TYPE.main color={'#6666FF'} lineHeight="20px">
                    <ShowMore style={{ marginRight: '10px', marginTop: '0px' }} /> Show all
                  </TYPE.main>
                </ButtonGradientBorder>
              </BasicLink>
            </>
          </FullWrapper>
        </PageWrapper>
      </Container>
    </>
  )
}

export default AllPairsPage
