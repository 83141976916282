import 'feather-icons'
import React, { useEffect, useMemo, useState } from 'react'
import { Flex, Text } from 'rebass'
import styled from 'styled-components'
import { StringParam, useQueryParams } from 'use-query-params'
import { FullWrapper, PageWrapper } from '../components'
import Column from '../components/Column'
import Header from '../components/Header'

import { Input } from '../components/Input'
import { PairsTable } from '../components/PairsTable'
import { ActiveSortItemBottom, SortText } from '../components/PairTransactionsTable'
import Row, { RowFixed } from '../components/Row'
import { useGlobalData } from '../contexts/GlobalData'
import { shouldFilterOutPair, usePairs } from '../contexts/PairData'
import { TYPE } from '../Theme'
import { Container } from '../Theme/wrappers'
import { searchPairs } from '../utils/filter'
import { PairTabs } from './AllPairsPage'
import LocalLoader from '../components/LocalLoader'
import { LoaderThin } from '../components/LoaderThin'
import { BasicLink } from '../components/Link'
import { formatAmount } from '../utils/formatAmount'
import { ButtonDark } from '../components/ButtonStyled'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;

  @media screen and (max-width: 540px) {
    grid-template-columns: 1fr;
  }
`
export const pairTabFilters = [PairTabs.SECURITY, PairTabs.CRYPTO]

function AllPoolsPage({ type = PairTabs.CRYPTO }) {
  const [searchValue, setSearchValue] = useState('')
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSearchChange = (e) => {
    const search = e.currentTarget.value
    setSearchValue(search)
    const normalizedSearch = search.trim()?.toLowerCase()
    if (normalizedSearch.length === 0) {
      setFilteredPairs({})
    }
    if (normalizedSearch?.length < 2) {
      return
    }
    const newPairs = searchPairs(usedPairs, normalizedSearch)
    if (newPairs) {
      setFilteredPairs(newPairs)
    }
  }

  const { secPairs = {}, cryptoPairs = {} } = usePairs()
  const usedPairs = useMemo(() => {
    if (type === PairTabs.SECURITY) {
      return secPairs
    }
    return cryptoPairs
  }, [cryptoPairs, secPairs, type])

  const [filteredPairs, setFilteredPairs] = useState({})

  const visiblePools = Object.keys(filteredPairs).length || searchValue.length ? filteredPairs : usedPairs
  const { totalValueLockedSecurity, totalValueLockedCrypto } = useGlobalData()
  const tvl = useMemo(() => {
    switch (type) {
      case PairTabs.SECURITY:
        return totalValueLockedSecurity
      default:
        return totalValueLockedCrypto
    }
  }, [type, totalValueLockedCrypto, totalValueLockedSecurity])
  return (
    <>
      <Header />
      <Container width={['100%']} margin={'auto'}>
        <PageWrapper>
          <FullWrapper>
            {/* <BasicLink to="/home"> */}

            <Flex onClick={() => history.goBack()} style={{ gap: '5px', cursor: 'pointer', marginTop: '70px' }}>
              <TYPE.lightText marginLeft="4px"> &#8592; Back</TYPE.lightText>
            </Flex>
            {/* <ButtonDark
                style={{
                  marginTop: '60px',
                  background: 'none',
                  color: 'black',
                  border: '1px solid #E6E6FF',
                  borderRadius: '6px',
                  padding: '10px 30px',
                }}
              >
                Back
              </ButtonDark> */}
            {/* </BasicLink> */}

            <Flex style={{ marginBottom: '30px' }} alignItems="center">
              <BasicLink key={`sort-item-${PairTabs.SECURITY}`} to="/pools/security">
                <Column style={{ marginRight: '40px' }}>
                  <SortText active={type === PairTabs.SECURITY}>
                    <Text style={{ marginBottom: '20px' }} fontSize={20}>
                      {PairTabs.SECURITY} Pools
                    </Text>
                  </SortText>
                  {type === PairTabs.SECURITY && <ActiveSortItemBottom />}
                </Column>
              </BasicLink>
              <BasicLink key={`sort-item-${PairTabs.CRYPTO}`} to="/pools/crypto">
                <Column>
                  <SortText active={type === PairTabs.CRYPTO}>
                    <Text style={{ marginBottom: '20px' }} fontSize={20}>
                      {PairTabs.CRYPTO} Pools
                    </Text>
                  </SortText>
                  {type === PairTabs.CRYPTO && <ActiveSortItemBottom />}
                </Column>
              </BasicLink>
            </Flex>

            {isMobile ? (
              <RowFixed style={{ marginBottom: '45px', display: 'block' }}>
                <Column>
                  <TYPE.primary fontSize={40} lineHeight="56px">
                    {type} pools
                  </TYPE.primary>
                  {type === 'Crypto' ? (
                    <TYPE.lightText fontWeight={400} marginTop={'10px'} marginBottom="9px">
                      Crypto Pools, also known as liquidity pools, are collective pools of digital assets held by
                      participants in the cryptocurrency ecosystem. These pools enable individuals to contribute their
                      cryptocurrencies or tokens to a shared liquidity pool, which in turn facilitates various
                      decentralized financial activities.
                    </TYPE.lightText>
                  ) : (
                    <TYPE.lightText fontWeight={400} marginTop={'10px'} marginBottom="9px">
                      Security Pools are decentralized financial mechanisms in the realm of cryptocurrencies designed to
                      enhance the security and stability of digital asset transactions. These pools serve as a
                      collective resource where participants contribute their funds to mitigate risks associated with
                      various crypto-related activities.
                    </TYPE.lightText>
                  )}
                </Column>
                <Column style={{ marginTop: '20px' }}>
                  <TYPE.primary style={{ color: '#8F8FB2' }} fontWeight={500} fontSize={13} lineHeight="27px">
                    Total Value locked:
                  </TYPE.primary>
                  {visiblePools && Object.keys(visiblePools)?.length !== 0 && (
                    <TYPE.primary color={'#6666FF'} fontSize={36} lineHeight="56px">
                      ${formatAmount(tvl, 0, 0)}
                    </TYPE.primary>
                  )}
                  {!visiblePools ||
                    (Object.keys(visiblePools)?.length === 0 && (
                      <Row marginTop={3}>
                        <LoaderThin size={32} />
                      </Row>
                    ))}
                </Column>
                {/* <Column>
    <TYPE.primary style={{ textTransform: 'uppercase' }} fontWeight={500} fontSize={18} lineHeight="27px">
      My ETH Rewards
    </TYPE.primary>
    <TYPE.primary fontSize={36} lineHeight="56px">
      0.015
    </TYPE.primary>
  </Column> */}
              </RowFixed>
            ) : (
              <RowFixed style={{ marginBottom: '45px' }}>
                <Column style={{ marginRight: '500px' }}>
                  <TYPE.primary fontSize={40} lineHeight="56px">
                    {type} pools
                  </TYPE.primary>
                  {type === 'Crypto' ? (
                    <TYPE.lightText fontWeight={400} marginTop={'10px'} marginBottom="9px">
                      Crypto Pools, also known as liquidity pools, are collective pools of digital assets held by
                      participants in the cryptocurrency ecosystem. These pools enable individuals to contribute their
                      cryptocurrencies or tokens to a shared liquidity pool, which in turn facilitates various
                      decentralized financial activities.
                    </TYPE.lightText>
                  ) : (
                    <TYPE.lightText fontWeight={400} marginTop={'10px'} marginBottom="9px">
                      Security Pools are decentralized financial mechanisms in the realm of cryptocurrencies designed to
                      enhance the security and stability of digital asset transactions. These pools serve as a
                      collective resource where participants contribute their funds to mitigate risks associated with
                      various crypto-related activities.
                    </TYPE.lightText>
                  )}
                </Column>
                <Column style={{ paddingLeft: '20px' }}>
                  <TYPE.primary style={{ color: '#8F8FB2' }} fontWeight={500} fontSize={13} lineHeight="27px">
                    Total Value locked:
                  </TYPE.primary>
                  {visiblePools && Object.keys(visiblePools)?.length !== 0 && (
                    <TYPE.primary color={'#6666FF'} fontSize={36} lineHeight="56px">
                      ${formatAmount(tvl, 0, 0)}
                    </TYPE.primary>
                  )}
                  {!visiblePools ||
                    (Object.keys(visiblePools)?.length === 0 && (
                      <Row marginTop={3}>
                        <LoaderThin size={32} />
                      </Row>
                    ))}
                </Column>
                {/* <Column>
    <TYPE.primary style={{ textTransform: 'uppercase' }} fontWeight={500} fontSize={18} lineHeight="27px">
      My ETH Rewards
    </TYPE.primary>
    <TYPE.primary fontSize={36} lineHeight="56px">
      0.015
    </TYPE.primary>
  </Column> */}
              </RowFixed>
            )}

            <Input marginBottom="45px" value={searchValue} placeholder="Search pools" onChange={onSearchChange} />

            {visiblePools &&
              Object.keys(visiblePools)?.length !== 0 &&
              (type === PairTabs.CRYPTO ? (
                <PairsTable
                  items={Object.keys(visiblePools)
                    .filter((poolId) => visiblePools[poolId] && !shouldFilterOutPair(visiblePools[poolId]))
                    .map((poolId) => visiblePools[poolId])}
                />
              ) : (
                <PairsTable items={Object.values(visiblePools)} />
              ))}

            {!visiblePools ||
              (Object.keys(visiblePools)?.length === 0 && (
                <Row>
                  <LocalLoader />
                </Row>
              ))}
          </FullWrapper>
        </PageWrapper>
      </Container>
    </>
  )
}

export default AllPoolsPage
