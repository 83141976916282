import 'feather-icons'
import { transparentize } from 'polished'
import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useMedia } from 'react-use'
import { Flex } from 'rebass'
import styled from 'styled-components'
import { ButtonGradientBorder, ButtonIXSGradient, ButtonIXSSecondaryGradient } from '../components/ButtonStyled'
import Column, { AutoColumn } from '../components/Column'
import CopyHelper from '../components/Copy'
import FormattedName from '../components/FormattedName'
import Header from '../components/Header'
import { BlockedMessageWrapper, BlockedWrapper, ContentWrapperLarge, PageWrapper } from '../components/index'
import Link, { BasicLink } from '../components/Link'
import PairChart from '../components/PairChart'
import { PairTransactionsTable } from '../components/PairTransactionsTable'
import { Card as StyledPoolCard, Divider } from '../components/PoolCard'
import { FixedPanel, TokenDetailsLayout } from '../components/PoolPage/styleds'
import { AutoRow, RowBetween, RowFixed } from '../components/Row'
import TokenLogo from '../components/TokenLogo'
import { BLOCKED_WARNINGS, PAIR_BLACKLIST, usedChain } from '../constants'
import { useEthPrice } from '../contexts/GlobalData'
import { usePairData, usePairs, usePairTransactions } from '../contexts/PairData'
import { useColor } from '../hooks'
import { ThemedBackground, TYPE } from '../Theme'
import { capitalize, formattedNum, formattedPercent, getPoolLink, getSwapLink, shortenAddress } from '../utils'
import { ExplorerDataType, getExplorerLink } from '../utils/etherscan'
import DoubleTokenLogo from '../components/DoubleLogo'

export const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 33px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`

export const Card = styled(StyledPoolCard)`
  padding: 42px 32px 0px 32px;
  width: 100%;
  height: 100%;
  max-width: -webkit-fill-available;
  max-height: 468px;
  height: 468px;
  background-color: #ffffff;
  background: #ffffff;
  margin-bottom: 20px;

  @media screen and (max-width: 1080px) {
    max-height: auto;
    height: auto;
    margin-bottom: 40px;
  }
`

function PoolPage({ pairAddress, history }) {
  const {
    token0,
    token1,
    reserve0,
    reserve1,
    reserveUSD,
    trackedReserveUSD,
    oneDayVolumeUSD,
    oneDayVolumeUntracked,
    liquidityChangeUSD,
    volumeChangeUSD,
    volumeChangeUntracked,
  } = usePairData(pairAddress)

  useEffect(() => {
    document.querySelector('body').scrollTo(0, 0)
  }, [])

  const transactions = usePairTransactions(pairAddress)
  const backgroundColor = useColor(pairAddress)

  const below1080 = useMedia('(max-width: 1080px)')
  const below540 = useMedia('(max-width: 540px)')
  const below1600 = useMedia('(max-width: 1600px)')
  const below900 = useMedia('(max-width: 900px)')

  const aspect = below1080 ? 60 / 28 : below1600 ? 60 / 28 : 60 / 22

  const formattedLiquidity = reserveUSD ? formattedNum(reserveUSD, true) : formattedNum(trackedReserveUSD, true)

  // volume
  const volume = !!oneDayVolumeUSD ? formattedNum(oneDayVolumeUSD, true) : formattedNum(oneDayVolumeUntracked, true)
  const usingUtVolume = oneDayVolumeUSD === 0 && !!oneDayVolumeUntracked
  const volumeChange = formattedPercent(!usingUtVolume ? volumeChangeUSD : volumeChangeUntracked)
  const { secPairs = {} } = usePairs()
  const isSecPair = Boolean(secPairs[pairAddress])
  const type = isSecPair ? 'security' : 'crypto'
  // get fees	  // get fees
  const fees =
    oneDayVolumeUSD || oneDayVolumeUSD === 0
      ? usingUtVolume
        ? formattedNum(oneDayVolumeUntracked * 0.003, true)
        : formattedNum(oneDayVolumeUSD * 0.003, true)
      : '-'

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
  }, [])
  const [ethPrice] = useEthPrice()
  const token0USD =
    token0?.derivedETH && ethPrice ? formattedNum(parseFloat(token0.derivedETH) * parseFloat(ethPrice), true) : ''

  const token1USD =
    token1?.derivedETH && ethPrice ? formattedNum(parseFloat(token1.derivedETH) * parseFloat(ethPrice), true) : ''
  // rates
  const token0Rate = reserve0 && reserve1 ? formattedNum(reserve1 / reserve0) : '-'
  const token1Rate = reserve0 && reserve1 ? formattedNum(reserve0 / reserve1) : '-'

  // formatted symbols for overflow
  const formattedSymbol0 = token0?.symbol.length > 6 ? token0?.symbol.slice(0, 5) + '...' : token0?.symbol
  const formattedSymbol1 = token1?.symbol.length > 6 ? token1?.symbol.slice(0, 5) + '...' : token1?.symbol
  const liquidityChange = formattedPercent(liquidityChangeUSD)

  if (PAIR_BLACKLIST.includes(pairAddress)) {
    return (
      <BlockedWrapper>
        <BlockedMessageWrapper>
          <AutoColumn gap="1rem" justify="center">
            <TYPE.light style={{ textAlign: 'center' }}>
              {BLOCKED_WARNINGS[pairAddress] ?? `This pair is not supported.`}
            </TYPE.light>
            <Link
              external={true}
              href={getExplorerLink(usedChain, pairAddress, ExplorerDataType.ADDRESS)}
            >{`More about ${shortenAddress(pairAddress)}`}</Link>
          </AutoColumn>
        </BlockedMessageWrapper>
      </BlockedWrapper>
    )
  }

  return (
    <>
      <Header />
      <PageWrapper style={{ background: '#F7F7FF', marginTop: '60px' }}>
        <ThemedBackground backgroundColor={transparentize(0.6, backgroundColor)} />
        <ContentWrapperLarge style={{ background: '#FFFFFF' }}>
          {/* <RowBetween>
            <TYPE.body>
              <BasicLink to={`/home`}>Home</BasicLink> →
              <BasicLink to={`/pools/${type}`} style={{ marginLeft: '3px' }}>
                {capitalize(type)} {'pools'}
              </BasicLink>
              → {token0?.symbol}-{token1?.symbol}
            </TYPE.body>
          </RowBetween> */}
          {token0 && (
            <>
              <Flex
                marginBottom={below540 ? '100px' : '0px'}
                justifyContent="space-between"
                flexDirection={below540 ? 'column' : 'row'}
              >
                <TYPE.header style={{ display: 'flex' }} marginBottom="16px" fontSize={36} lineHeight="56px">
                  <DoubleTokenLogo size={48} a0={token0.id} a1={token1.id} />{' '}
                  <span style={{ marginLeft: '30px' }}>
                    {token0?.symbol}/{token1?.symbol}
                  </span>
                </TYPE.header>
                <Flex flexDirection={below540 ? 'column' : 'row'} height="50px">
                  <Link external href={getPoolLink(token0?.id, token1?.id)}>
                    <ButtonGradientBorder
                      style={{ height: 50, width: below540 ? '100%' : 212, marginRight: 16, marginBottom: 8 }}
                    >
                      <TYPE.primary fontSize={14} color={'#6666FF'} lineHeight="20px">
                        Add liquidity
                      </TYPE.primary>
                    </ButtonGradientBorder>
                  </Link>
                  <Link external href={getSwapLink(token0?.id, token1?.id)}>
                    <ButtonIXSGradient style={{ height: 50, width: below540 ? '100%' : 212, marginBottom: 12 }}>
                      <TYPE.primary color={'#FFFFFF'} fontSize={18} lineHeight="20px">
                        Trade
                      </TYPE.primary>
                    </ButtonIXSGradient>
                  </Link>
                </Flex>
              </Flex>
              <AutoRow
                gap="6px"
                style={{
                  width: 'fit-content',
                  marginTop: below900 ? '1rem' : '0',
                  marginBottom: below900 ? '0' : '2rem',
                  flexWrap: 'wrap',
                }}
              >
                <FixedPanel onClick={() => history.push(`/token/${token0?.id}`)}>
                  <RowFixed>
                    <TokenLogo address={token0?.id} size={'16px'} />
                    <TYPE.main color={'#6666FF'} fontSize={'16px'} lineHeight={1} fontWeight={500} ml={'4px'}>
                      {token0 && token1
                        ? `1 ${formattedSymbol0} = ${token0Rate} ${formattedSymbol1} ${
                            parseFloat(token0?.derivedETH) ? '(' + token0USD + ')' : ''
                          }`
                        : '-'}
                    </TYPE.main>
                  </RowFixed>
                </FixedPanel>
                <FixedPanel onClick={() => history.push(`/token/${token1?.id}`)}>
                  <RowFixed>
                    <TokenLogo address={token1?.id} size={'16px'} />
                    <TYPE.main color={'#6666FF'} fontSize={'16px'} lineHeight={1} fontWeight={500} ml={'4px'}>
                      {token0 && token1
                        ? `1 ${formattedSymbol1} = ${token1Rate} ${formattedSymbol0}  ${
                            parseFloat(token1?.derivedETH) ? '(' + token1USD + ')' : ''
                          }`
                        : '-'}
                    </TYPE.main>
                  </RowFixed>
                </FixedPanel>
              </AutoRow>
              <Flex onClick={() => history.goBack()} style={{ gap: '5px', cursor: 'pointer' }}>
                <TYPE.lightText marginLeft="4px"> &#8592; Back</TYPE.lightText>
              </Flex>

              <CardsGrid>
                <Card>
                  <TYPE.lightText marginBottom="9px">Total Tokens Locked</TYPE.lightText>

                  <RowBetween marginBottom="15px">
                    <Flex alignItems="center">
                      <TokenLogo size="33px" address={token0?.id} />
                      <TYPE.primary fontWeight={700} fontSize={'14px'} marginLeft="14px">
                        {token0?.symbol}
                      </TYPE.primary>
                    </Flex>
                    <TYPE.main fontSize={20} lineHeight={1} fontWeight={500}>
                      <RowFixed style={{ fontSize: '13px' }}>
                        {reserve0 ? `${formattedNum(reserve0 / 1000000)}m` : ''}{' '}
                      </RowFixed>
                    </TYPE.main>
                  </RowBetween>

                  <RowBetween marginBottom="30px">
                    <Flex alignItems="center">
                      <TokenLogo size="33px" address={token1?.id} />
                      <TYPE.primary fontWeight={700} fontSize={'14px'} marginLeft="14px">
                        {token1?.symbol}
                      </TYPE.primary>
                    </Flex>
                    <TYPE.main fontSize={20} lineHeight={1} fontWeight={500}>
                      <RowFixed style={{ fontSize: '13px' }}>
                        {reserve1 ? `${formattedNum(reserve1 / 1000000)}m` : ''}{' '}
                      </RowFixed>
                    </TYPE.main>
                  </RowBetween>

                  <Divider style={{ marginBottom: 27 }} />

                  <AutoColumn style={{ marginBottom: 18 }}>
                    <RowBetween marginBottom="2px">
                      <TYPE.lightText>TVL</TYPE.lightText>
                    </RowBetween>
                    <RowBetween>
                      <TYPE.primary>{formattedLiquidity}</TYPE.primary>
                      <TYPE.main>{liquidityChange}</TYPE.main>
                    </RowBetween>
                  </AutoColumn>
                  <Divider style={{ marginBottom: 27 }} />
                  <AutoColumn style={{ marginBottom: 18 }}>
                    <RowBetween marginBottom="2px">
                      <TYPE.lightText>Volume 24h</TYPE.lightText>
                    </RowBetween>
                    <RowBetween>
                      <TYPE.primary>{volume}</TYPE.primary>
                      <TYPE.main>{volumeChange}</TYPE.main>
                    </RowBetween>
                  </AutoColumn>
                  <Divider style={{ marginBottom: 27 }} />
                  <AutoColumn style={{ marginBottom: 18 }}>
                    <RowBetween marginBottom="2px">
                      <TYPE.lightText>24h Fees</TYPE.lightText>
                    </RowBetween>
                    <RowBetween>
                      <TYPE.primary>{fees}</TYPE.primary>
                      <TYPE.main>{volumeChange}</TYPE.main>
                    </RowBetween>
                  </AutoColumn>
                </Card>
                <Card
                  style={{
                    gridColumn: below1080 ? '1' : '2/4',
                    gridRow: below1080 ? '2 / span 1' : '1/5',
                  }}
                >
                  <PairChart
                    address={pairAddress}
                    color={'#6666FF'}
                    base0={reserve1 / reserve0}
                    base1={reserve0 / reserve1}
                  />
                </Card>
              </CardsGrid>

              <TYPE.header marginBottom="10px" fontSize={20} fontWeight={700} lineHeight="56px">
                Transactions
              </TYPE.header>

              {transactions && (
                <PairTransactionsTable
                  items={[...transactions.burns, ...transactions.swaps, ...transactions.mints].sort(
                    (a, b) => +b.transaction.timestamp - +a.transaction.timestamp
                  )}
                />
              )}
            </>
          )}

          {token0 && token1 ? (
            <>
              <TYPE.header marginBottom="10px" fontWeight={700} fontSize={20} lineHeight="56px">
                Pair Information
              </TYPE.header>
              <Card rounded p={20} style={{ height: 'fit-content', background: '#F7F7F8' }}>
                <TokenDetailsLayout>
                  <Column>
                    <TYPE.lightText style={{ marginTop: '-10px' }}>Pair Name</TYPE.lightText>
                    <TYPE.main style={{ marginTop: '15px' }}>
                      <RowFixed>
                        <FormattedName text={token0?.symbol ?? ''} maxCharacters={8} />
                        -
                        <FormattedName text={token1?.symbol ?? ''} maxCharacters={8} />
                      </RowFixed>
                    </TYPE.main>
                  </Column>
                  <Column>
                    <TYPE.lightText>Pair Address</TYPE.lightText>
                    <AutoRow
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #E6E6FF',
                        background: '#FFFFFF',
                        padding: '0px 20px 12px 10px',
                      }}
                      align="flex-end"
                    >
                      <TYPE.main
                        style={{
                          marginTop: '.5rem',
                        }}
                      >
                        {pairAddress.slice(0, 6) + '...' + pairAddress.slice(38, 42)}
                      </TYPE.main>
                      <CopyHelper toCopy={pairAddress} />
                    </AutoRow>
                  </Column>
                  <Column>
                    <TYPE.lightText>
                      <RowFixed>
                        <FormattedName text={token0?.symbol ?? ''} maxCharacters={8} />{' '}
                        <span style={{ marginLeft: '4px' }}>Address</span>
                      </RowFixed>
                    </TYPE.lightText>
                    <AutoRow
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #E6E6FF',
                        background: '#FFFFFF',
                        padding: '0px 20px 12px 10px',
                      }}
                      align="flex-end"
                    >
                      <TYPE.main>{token0 && token0.id.slice(0, 6) + '...' + token0.id.slice(38, 42)}</TYPE.main>
                      <CopyHelper toCopy={token0?.id} />
                    </AutoRow>
                  </Column>
                  <Column>
                    <TYPE.lightText>
                      <RowFixed>
                        <FormattedName text={token1?.symbol ?? ''} maxCharacters={8} />{' '}
                        <span style={{ marginLeft: '4px' }}>Address</span>
                      </RowFixed>
                    </TYPE.lightText>
                    <AutoRow
                      align="flex-end"
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #E6E6FF',
                        background: '#FFFFFF',
                        padding: '0px 20px 12px 10px',
                      }}
                    >
                      <TYPE.main>{token1 && token1.id.slice(0, 6) + '...' + token1.id.slice(38, 42)}</TYPE.main>
                      <CopyHelper toCopy={token1?.id} />
                    </AutoRow>
                  </Column>
                  <ButtonIXSSecondaryGradient
                    as={Link}
                    external
                    style={{ color: '#6666ff', textAlign: 'center', fontWeight: 'bold' }}
                    href={getExplorerLink(usedChain, pairAddress, ExplorerDataType.ADDRESS)}
                  >
                    View on PolygonScan ↗
                  </ButtonIXSSecondaryGradient>
                </TokenDetailsLayout>
              </Card>
            </>
          ) : (
            <h1>Loading...</h1>
          )}
        </ContentWrapperLarge>
      </PageWrapper>
    </>
  )
}

export default withRouter(PoolPage)
