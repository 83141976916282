import styled from 'styled-components'
import { ArrowLeft } from 'react-feather'
import { Link } from 'react-router-dom'
import React, { ReactNode } from 'react'

export const StyledInternalLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.text2};
  font-weight: 600;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`

const BackArrowLink = styled(StyledInternalLink)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.text6};

  &:hover {
    text-decoration: none;
  }
`

export function BackArrow({ to, children }: { to: string; children: ReactNode }) {
  return (
    <BackArrowLink to={to}>
      <ArrowLeft />
      {children}
    </BackArrowLink>
  )
}

export const SvgIconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`
