import React from 'react'
import { Flex } from 'rebass'
import styled from 'styled-components'
import { TYPE } from '../../Theme'
import { formattedNum, getPoolLink } from '../../utils'
import { formatAmount } from '../../utils/formatAmount'
import { PrimaryButton } from '../ButtonStyled'
import { AutoColumn } from '../Column'
import DoubleTokenLogo from '../DoubleLogo'
import Link, { BasicLink } from '../Link'
import { RowBetween } from '../Row'

export const Card = styled.div`
  max-height: 499px;
  max-width: 380px;
  // border-radius: 30px;
  border: 1px solid #e6e6ff;
    // background: radial-gradient(93.65% 93.65% at 58.57% 22.42%, rgba(206, 20, 132, 0.033) 0%, rgba(26, 18, 58, 0) 100%),
    rgba(44, 37, 74, 0.5);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  padding: 20px 36px 36px 24px;
  :hover {
    transform: translatey(-5px);
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e6e6ff;
  opacity: 0.9;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
`

export interface CryptoCardProps {
  item: any
  allPairs: any
}

export default function CryptoCard({ allPairs, item }: CryptoCardProps): JSX.Element {
  const pairData = allPairs[item?.id || '']
  return pairData ? (
    <Card>
      {/* <CardHeader /> */}
      <Flex
        // style={{ marginTop: '-25px' }}
        alignItems="center"
        marginBottom="30px"
        as={BasicLink}
        to={`/pools/${item?.id}`}
      >
        <RowBetween>
          <TYPE.primary style={{ fontSize: '18px' }}>
            {pairData.token0.symbol + '/' + pairData.token1.symbol}
          </TYPE.primary>
          <DoubleTokenLogo size={48} a0={item.token0.id} a1={item.token1.id} />
        </RowBetween>
      </Flex>
      {/* <Flex marginBottom="10px"></Flex> */}
      <AutoColumn as={BasicLink} to={`/pools/${item?.id}`}>
        <RowBetween>
          <TYPE.lightText>Volume</TYPE.lightText>
          <TYPE.lightText>Price change</TYPE.lightText>
        </RowBetween>
        <RowBetween marginBottom="14px">
          <TYPE.primary color="#292933"> {`${formatAmount(+Number(pairData.volumeUSD).toFixed(1))}$`}</TYPE.primary>
          <TYPE.primary
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color:
                +pairData.liquidityChangeUSD > 0 ? '#0EC080' : +pairData.liquidityChangeUSD < 0 ? '#FF6D6D' : '#292933',
            }}
          >
            {`${formatAmount(+pairData.liquidityChangeUSD.toFixed(2), 2, 2)}%`}
          </TYPE.primary>
        </RowBetween>
      </AutoColumn>
      <AutoColumn as={BasicLink} to={`/pools/${item?.id}`}></AutoColumn>
      <AutoColumn as={BasicLink} to={`/pools/${item?.id}`}>
        <Divider style={{ margin: '10px 0px' }} />
        {/* <RowBetween display="flex" justifyContent="spaceBetween" marginBottom="8px"> */}
        {/*   <TYPE.lightText>24H Volume</TYPE.lightText> */}
        {/*   <TYPE.lightText color={'#292933'} style={{ fontWeight: '500' }}> */}
        {/*     {formattedNum(pairData.oneDayVolumeUSD.toFixed(0), true)} */}
        {/*   </TYPE.lightText> */}
        {/* </RowBetween> */}
        {/* <Divider style={{ margin: '10px 0px' }} /> */}
        <RowBetween display="flex" justifyContent="spaceBetween">
          <TYPE.lightText>TVL</TYPE.lightText>
          <TYPE.lightText color={'#292933'} style={{ fontWeight: '500' }}>
            {formattedNum(pairData.reserveUSD || pairData.trackedReserveUSD, true)}
          </TYPE.lightText>
        </RowBetween>
        <Divider style={{ margin: '10px 0px 20px 0px' }} />
      </AutoColumn>
      <Link external href={getPoolLink(item?.token0?.id, item?.token1?.id)}>
        <PrimaryButton>Provide liquidity</PrimaryButton>
      </Link>
    </Card>
  ) : null
}
