import React from 'react'
import { Button as RebassButton } from 'rebass/styled-components'
import styled from 'styled-components'
import { Plus, ChevronDown, ChevronUp } from 'react-feather'
import { darken, transparentize } from 'polished'
import { RowBetween } from '../Row'
import { StyledIcon } from '..'
import { gradientBorder } from '../../Theme'

const Base = styled(RebassButton)`
  padding: 8px 12px;
  font-size: 0.825rem;
  font-weight: 600;
  border-radius: 12px;
  cursor: pointer;
  outline: none;
  border: 1px solid transparent;
  outline: none;
  border-bottom-right-radius: ${({ open }) => open && '0'};
  border-bottom-left-radius: ${({ open }) => open && '0'};
`

const BaseCustom = styled(RebassButton)`
  padding: 16px 12px;
  font-size: 0.825rem;
  font-weight: 400;
  border-radius: 12px;
  cursor: pointer;
  outline: none;
`

const Dull = styled(Base)`
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: black;
  height: 100%;
  font-weight: 400;
  &:hover,
  :focus {
    background-color: rgba(255, 255, 255, 0.25);
    border-color: rgba(255, 255, 255, 0.25);
  }
  &:focus {
    box-shadow: 0 0 0 1pt rgba(255, 255, 255, 0.25);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.25);
    border-color: rgba(255, 255, 255, 0.25);
  }
`

export default function ButtonStyled({ children, ...rest }) {
  return <Base {...rest}>{children}</Base>
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const ButtonLight = styled(Base)`
  border: 1px solid #e6e6ff;
  padding: 15px 25px;
  background: none;
  color: ${({ color, theme }) => (color ? darken(0.1, color) : theme.primary1)};

  min-width: fit-content;
  border-radius: 6px;
  white-space: nowrap;

  a {
    color: ${({ color, theme }) => (color ? darken(0.1, color) : theme.primary1)};
  }

  :hover {
    background-color: ${({ color, theme }) =>
      color ? transparentize(0.8, color) : transparentize(0.8, theme.primary1)};
  }
`

export function ButtonDropdown({ disabled = false, children, open, ...rest }) {
  return (
    <ButtonFaded {...rest} disabled={disabled} ope={open}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        {open ? (
          <StyledIcon>
            <ChevronUp size={24} />
          </StyledIcon>
        ) : (
          <StyledIcon>
            <ChevronDown size={24} />
          </StyledIcon>
        )}
      </RowBetween>
    </ButtonFaded>
  )
}

export const ButtonDark = styled(Base)`
  background-color: #6666ff;
  color: white;
  padding: 15px 55px;
  width: fit-content;
  border-radius: 6px;
  white-space: nowrap;

  :hover {
    background-color: ${({ color, theme }) => (color ? darken(0.1, color) : darken(0.1, theme.primary1))};
  }
`

export const ButtonFaded = styled(Base)`
  background-color: ${({ theme }) => theme.bg2};
  color: (255, 255, 255, 0.5);
  white-space: nowrap;

  :hover {
    opacity: 0.5;
  }
`

export const PrimaryButton = styled(BaseCustom)`
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  padding: 12px 16px;
  border: 1px solid #e6e6ff;
  color: #6666ff;
  font-size: 14px;
  font-weight: 600;
`

export const ButtonIXSGradient = styled(PrimaryButton)`
  height: 36px;
  width: 221px;
  position: relative;
  cursor: pointer;
  border: none;
  white-space: no-wrap;
  padding: 8px 0px;

  :hover {
    @media (min-width: 1000px) {
      opacity: 0.8;
    }
  }
  :active {
    opacity: 0.9;
  }
  border-radius: 8px;
  background-color: #6666ff;
  background: #6666ff;
  color: #ffffff;
`
export const ButtonGradientBorder = styled(ButtonIXSGradient)`
  background-color: transparent;
  background: transparent;
  border: 1px solid #E6E6FF;
  width: auto;
  height: auto;
  font
  // :focus,
  // :hover {
  //   background-color: transparent;
  //   background: transparent;
  // }
`

export const ButtonIXSSecondaryGradient = styled(ButtonIXSGradient)`
  background: none;
  border: 1px solid #e6e6ff;
  color: #6666ff;
  padding: 12px 16px;
  border-radius: 6px;
`

export const SecondaryButton = styled(BaseCustom)`
  position: relative;
  width: 221px;
  background: transparent;
  border-radius: 40px;
  padding: 8px 0px;
  border: solid 2px transparent;
  background-image: linear-gradient(116.36deg, #7b42a9 33.43%, #ed0376 95.41%);
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;

  // ::before {
  //   content: '';
  //   position: absolute;
  //   inset: 0;
  //   background: ${({ theme }) => theme.background1};
  //   z-index: -1;
  //   margin: 1px;
  //   border-radius: inherit;
  // }
`

export function ButtonPlusDull({ disabled, children, ...rest }) {
  return (
    <Dull {...rest}>
      <ContentWrapper>
        <Plus size={16} />
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
      </ContentWrapper>
    </Dull>
  )
}

export function ButtonCustom({ children, bgColor, color, ...rest }) {
  return (
    <BaseCustom bg={bgColor} color={color} {...rest}>
      {children}
    </BaseCustom>
  )
}

export const OptionButton = styled.div`
  font-weight: 500;
  width: fit-content;
  white-space: nowrap;
  padding: 6px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.bg4};
  background-color: ${({ active, theme }) => active && '#6666FF'};
  color: ${({ theme, active }) => active && 'White'};

  :hover {
    cursor: ${({ disabled }) => !disabled && 'pointer'};
  }
`
