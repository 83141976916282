import React, { useState } from 'react'
// import styled from 'styled-components'
import LogoDark from '../../assets/newLogo.svg'
import { TYPE } from '../../Theme'
import { getIxswapAppLink } from '../../utils'
import { ButtonIXSGradient } from '../ButtonStyled'
import Link, { BasicLink } from '../Link'
import { isMobile } from 'react-device-detect'
import styled, { CSSObject } from 'styled-components'
import { ReactComponent as SideBar } from '../../assets/sideBar.svg'
import { ReactComponent as Close } from '../../assets/close.svg'
import { StyledIcon } from '..'
import { ArrowDown, Bookmark } from 'react-feather'
import { ReactComponent as DropDown } from '../../assets/dropDownNew.svg'

interface HeaderProps {
  hideButton?: boolean
}

interface MobileHeaderWrapperProps {
  isOpen: boolean
  theme: CSSObject
}

interface MobileHeaderWrapperProps {
  isOpen: boolean
}
const MobileHeaderWrapper = styled.div<MobileHeaderWrapperProps>`
  ${({ theme }) => theme.flexColumn}
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.onlyLight};
  margin: 0 10px; /* Add margin to the left and right */
  transform: translateX(${({ isOpen }) => (isOpen ? '0' : '-100%')});
  transition: transform 0.3s ease-in-out;
  z-index: 23;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  height: 50px;
  justify-content: space-between;
  position: fixed;
  padding: 33px 15px;
  top: 0;
  z-index: 3;
  display: grid;
  grid-template-columns: auto 1fr 200px 10px;
  gap: 200px;
  align-items: center;
  background: ${({ theme }) => theme.onlyLight};
  display: none;

  @media (min-width: 768px) {
    display: grid;
  }
`

// ... (other imports)

const MenuIcon = styled.div<MobileHeaderWrapperProps>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-right: 20px;
  div {
    width: 25px;
    height: 3px;
    background: ${({ theme }) => theme.textColor};
    margin: 3px 0;
    transition: transform 0.3s ease-in-out;
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    div:nth-child(1) {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
    div:nth-child(2) {
      opacity: 0;
    }
    div:nth-child(3) {
      transform: rotate(45deg) translate(-5px, -6px);
    }
  `}

  // Remove margin from right side of SideBar
  margin-left: auto; // Add this line
`

// ... (rest of the code)

const MobileLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: 60px;
  width: calc(100% - 20px); /* Adjust width to consider the left and right margin */
`

const MobileMenuItem = styled.a`
  position: relative;
  padding: 12px 16px;
  text-decoration: none;
  color: black;
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin: 10px 20px;
  width: -webkit-fill-available;

  &:hover {
    color: #6666ff;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: #e6e6ff;
  }
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  width: fit-content;
  :hover {
    cursor: pointer;
  }
`

const IXSwapButton = styled.a`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: #ffffff;
  background: #6666ff;
  border-radius: 6px;
  text-decoration: none;
  // padding: 10px;
`

const IXSIcon = styled.div``

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
`

const DropdownMenu = styled.div`
  position: absolute;
  display: none;
  flex-direction: column;
  background-color: #f9f9f9;
  min-width: 160px;
  z-index: 1;
`

// const FarmingDropdown = styled.div`
//   position: relative;
//   display: inline-block;

//   &:hover ${DropdownMenu} {
//     display: flex;
//   }
// `

const MenuItem = styled.a`
  padding: 12px 16px;
  text-decoration: none;
  color: black;
  display: block;
  font-size: 13px;
  &:hover {
    color: #6666ff;
  }
`

const Spacer = styled.div`
  flex-grow: 1;
`

const FarmingDropdownMenu = styled(DropdownMenu)`
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  flex-direction: column;
  background-color: #f9f9f9;
  min-width: 160px;
  z-index: 1;
`

const FarmingDropdown = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${FarmingDropdownMenu} {
    display: flex;
  }
`

export default function Header({ hideButton = false }) {
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }
  return (
    <>
      {isMobile ? (
        <>
          <div style={{ display: 'flex' }}>
            <Title as={BasicLink} to={'/home'}>
              <img style={{ marginLeft: '20px' }} width={'120px'} src={LogoDark} alt="logo" />
            </Title>
            <MenuIcon isOpen={isOpen} onClick={toggleMenu}>
              <SideBar />
            </MenuIcon>
          </div>
          <MobileHeaderWrapper isOpen={isOpen}>
            <Close
              onClick={closeMenu}
              style={{ position: 'absolute', top: '20px', right: '20px', cursor: 'pointer' }}
            />
            <MobileLinksContainer>
              <MobileMenuItem href="/charts" onClick={closeMenu}>
                Charts
              </MobileMenuItem>
              <MobileMenuItem href="https://app.ixswap.io/#/launchpad">Launchpad</MobileMenuItem>
              <MobileMenuItem href="https://app.ixswap.io/#/security-tokens/tokens">Security Tokens</MobileMenuItem>
              <MobileMenuItem href="https://app.ixswap.io/#/pool">Liquidity Pools</MobileMenuItem>
              <MobileMenuItem href="https://app.ixswap.io/#/swap">Swap/Trade</MobileMenuItem>
              {/* <MobileMenuItem href="/farming">Farming</MobileMenuItem>
              <MobileMenuItem href="https://ixswap.defiterm.io/">Live Pools</MobileMenuItem>
              <MobileMenuItem href="https://app.ixswap.io/#/vesting">Token Sale Distributions</MobileMenuItem> */}

              <FarmingDropdown>
                <MobileMenuItem>Farming</MobileMenuItem>
                <FarmingDropdownMenu>
                  <MenuItem href="https://ixswap.defiterm.io/">Live Pools</MenuItem>
                  <MenuItem href="https://app.ixswap.io/#/vesting">Token Sale Distributions</MenuItem>
                </FarmingDropdownMenu>
              </FarmingDropdown>
            </MobileLinksContainer>
          </MobileHeaderWrapper>{' '}
          {/* <IXSwapButton style={{ margin: '20px 20px 0px 20px' }} href={getIxswapAppLink()}>
            <p style={{ color: 'white' }} color={'white'}>
              Go to IXSwap Prime
            </p>
          </IXSwapButton> */}
        </>
      ) : (
        <HeaderWrapper>
          <Title as={BasicLink} to={'/home'}>
            <img style={{ marginLeft: '40px' }} width={'180px'} src={LogoDark} alt="logo" />
          </Title>
          <LinksContainer>
            <MenuItem href="/charts">Charts</MenuItem>
            <MenuItem href="https://app.ixswap.io/#/launchpad">Launchpad</MenuItem>
            <MenuItem href="https://app.ixswap.io/#/security-tokens/tokens">Security Tokens</MenuItem>
            <MenuItem href="https://app.ixswap.io/#/pool">Liquidity Pools</MenuItem>
            <MenuItem href="https://app.ixswap.io/#/swap">Swap/Trade</MenuItem>
            <FarmingDropdown>
              <MenuItem>
                Farming {/* <StyledIcon> */}
                <DropDown style={{ marginTop: '1px', width: '10px', height: '10px' }} />
                {/* </StyledIcon> */}
              </MenuItem>
              <FarmingDropdownMenu>
                <MenuItem href="https://ixswap.defiterm.io/">Live Pools</MenuItem>
                <MenuItem href="https://app.ixswap.io/#/vesting">Token Sale Distributions</MenuItem>
              </FarmingDropdownMenu>
            </FarmingDropdown>
            <Spacer />
          </LinksContainer>
          {/* {!hideButton && (
            <IXSwapButton href={getIxswapAppLink()}>
              <p style={{ color: 'white' }} color={'white'}>
                Go to IXSwap Prime
              </p>
            </IXSwapButton>
          )} */}
        </HeaderWrapper>
      )}
    </>
  )
}
