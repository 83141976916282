export const searchPairs = (pairs, searchKey) => {
  const keys = Object.keys(pairs)
  const newPairs = keys.reduce((accum, item) => {
    const currentPair = pairs[item]
    const token0 = pairs[item]?.token0
    const token1 = pairs[item]?.token1
    const name0 = token0.name?.toLowerCase()
    const symbol0 = token0.symbol?.toLowerCase()
    const symbol1 = token1.symbol?.toLowerCase()
    const name1 = token1.name?.toLowerCase()
    const matches =
      name0.includes(searchKey) ||
      name1.includes(searchKey) ||
      symbol0.includes(searchKey) ||
      symbol1.includes(searchKey) ||
      currentPair.id === searchKey ||
      token0.id === searchKey ||
      token1.id === searchKey
    if (matches) {
      return {
        ...accum,
        [item]: currentPair,
      }
    }
    return {
      ...accum,
    }
  }, {})
  return newPairs
}
