import React, { useEffect, useRef, useState } from 'react'
import { useMedia } from 'react-use'
import { Flex } from 'rebass'
import { Area, AreaChart, Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import styled from 'styled-components'
import { EmptyCard } from '..'
import { timeframeOptions } from '../../constants'
import { useDarkModeManager } from '../../contexts/LocalStorage'
import { useHourlyRateData, usePairChartData, usePairData } from '../../contexts/PairData'
import { TYPE } from '../../Theme'
import { formattedNum, getTimeframe, toK, toNiceDate, toNiceDateYear } from '../../utils'
import Column from '../Column'
import DropdownSelect from '../DropdownSelect'
import { ActiveSortItemBottom, SortText } from '../PairTransactionsTable'
import { RowBetween, RowStart } from '../Row'

const ChartWrapper = styled.div`
  height: 100%;
  max-height: 340px;

  @media screen and (max-width: 600px) {
    min-height: 200px;
  }
`

const CHART_VIEW = {
  VOLUME: 'Volume',
  LIQUIDITY: 'Liquidity',
  TVL: 'TVL',
  PRICE: 'Price',
}

const filterItems = [
  {
    value: CHART_VIEW.VOLUME,
  },
  {
    value: CHART_VIEW.TVL,
  },
  {
    value: CHART_VIEW.PRICE,
  },
]
const timeFrames = [
  { value: timeframeOptions.WEEK, key: '1W' },
  { value: timeframeOptions.MONTH, key: '1M' },
  {
    value: timeframeOptions.ALL_TIME,
    key: 'All',
  },
]
const PairChart = ({ address, color, base0, base1 }) => {
  const [chartFilter, setChartFilter] = useState(CHART_VIEW.VOLUME)

  const [timeWindow, setTimeWindow] = useState(timeframeOptions.MONTH)

  const [darkMode] = useDarkModeManager()
  const textColor = darkMode ? '#8F8FB2' : 'black'

  // update the width on a window resize
  const ref = useRef()
  const isClient = typeof window === 'object'
  const [width, setWidth] = useState(ref?.current?.container?.clientWidth)
  const [height, setHeight] = useState(ref?.current?.container?.clientHeight)
  useEffect(() => {
    if (!isClient) {
      return false
    }
    function handleResize() {
      setWidth(ref?.current?.container?.clientWidth ?? width)
      setHeight(ref?.current?.container?.clientHeight ?? height)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [height, isClient, width]) // Empty array ensures that effect is only run on mount and unmount

  // get data for pair, and rates
  const pairData = usePairData(address)
  let chartData = usePairChartData(address)
  const hourlyData = useHourlyRateData(address, timeWindow)
  const hourlyRate0 = hourlyData && hourlyData[0]
  const hourlyRate1 = hourlyData && hourlyData[1]

  const below1600 = useMedia('(max-width: 1600px)')
  const below1080 = useMedia('(max-width: 1080px)')
  const below600 = useMedia('(max-width: 600px)')
  const [latestValue, setLatestValue] = useState()
  let utcStartTime = getTimeframe(timeWindow)
  chartData = chartData?.filter((entry) => entry.date >= utcStartTime)
  if (chartData && chartData.length === 0) {
    return (
      <ChartWrapper>
        <EmptyCard height="300px">
          <TYPE.body1>No historical data yet</TYPE.body1>
        </EmptyCard>{' '}
      </ChartWrapper>
    )
  }

  const aspect = below1080 ? 60 / 20 : below1600 ? 60 / 28 : 60 / 22
  const initializeLatestvalue = (selectedKey) => {
    if (chartData?.length) {
      if (selectedKey === CHART_VIEW.VOLUME) {
        if (chartData[chartData.length - 1].dailyVolumeUSD !== undefined) {
          setLatestValue(chartData[chartData.length - 1].dailyVolumeUSD)
        }
      }
      if (selectedKey === CHART_VIEW.TVL) {
        if (chartData[chartData.length - 1].reserveUSD !== undefined) {
          setLatestValue(chartData[chartData.length - 1].reserveUSD)
        }
      }
    }
  }
  return (
    <ChartWrapper>
      {below600 ? (
        <RowBetween mb={40}>
          <DropdownSelect options={CHART_VIEW} active={chartFilter} setActive={setChartFilter} color={color} />
          <DropdownSelect options={timeframeOptions} active={timeWindow} setActive={setTimeWindow} color={color} />
        </RowBetween>
      ) : (
        <Column>
          <RowBetween>
            <Flex alignItems="center">
              {timeFrames.map(({ key, value }) => (
                <Column key={`sort-item-${value}`}>
                  <SortText
                    onClick={() => {
                      setTimeWindow(value)
                    }}
                    active={timeWindow === value}
                  >
                    {key}
                  </SortText>
                  {timeWindow === value && <ActiveSortItemBottom />}
                </Column>
              ))}
            </Flex>
            <Flex alignItems="center">
              {filterItems.map(({ value }) => (
                <Column key={`sort-item-${value}`}>
                  <SortText
                    onClick={() => {
                      setTimeWindow(timeframeOptions.ALL_TIME)
                      initializeLatestvalue(value)
                      setChartFilter(value)
                    }}
                    active={chartFilter === value}
                  >
                    {value}
                  </SortText>
                  {chartFilter === value && <ActiveSortItemBottom />}
                </Column>
              ))}
            </Flex>
          </RowBetween>
          <RowStart my={15}>
            <TYPE.primary>{formattedNum(latestValue)} $</TYPE.primary>
          </RowStart>
        </Column>
      )}
      {chartFilter === CHART_VIEW.TVL && (
        <ResponsiveContainer aspect={aspect} width="90%" height="100%">
          <AreaChart
            margin={{ top: 10, right: 10, bottom: 6, left: 0 }}
            barCategoryGap={1}
            data={chartData}
            height="100%"
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={color} stopOpacity={0.35} />
                <stop offset="95%" stopColor={color} stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              tickLine={false}
              axisLine={false}
              interval="preserveEnd"
              tickMargin={14}
              minTickGap={80}
              tickFormatter={(tick) => toNiceDate(tick)}
              dataKey="date"
              tick={{ fill: textColor }}
              type={'number'}
              domain={['dataMin', 'dataMax']}
            />
            <YAxis
              type="number"
              orientation="right"
              tickFormatter={(tick) => '$' + toK(tick)}
              axisLine={false}
              tickLine={false}
              interval="preserveEnd"
              minTickGap={80}
              yAxisId={0}
              tickMargin={16}
              tick={{ fill: textColor }}
            />
            <Tooltip
              cursor={true}
              formatter={(val) => {
                if (setLatestValue && latestValue !== val) {
                  setLatestValue(val)
                }
                return formattedNum(val, true)
              }}
              labelFormatter={(label) => toNiceDateYear(label)}
              labelStyle={{ paddingTop: 4 }}
              contentStyle={{
                padding: '10px 14px',
                borderRadius: 10,
                borderColor: color,
                color: 'black',
              }}
              wrapperStyle={{ top: -70, left: -10 }}
            />
            <Area
              strokeWidth={2}
              dot={false}
              type="monotone"
              name={' (USD)'}
              dataKey={'reserveUSD'}
              yAxisId={0}
              stroke={color}
              fill="url(#colorUv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}

      {chartFilter === CHART_VIEW.VOLUME && (
        <ResponsiveContainer aspect={aspect} width="90%" height="100%">
          <BarChart
            margin={{ top: 0, right: 0, bottom: 6, left: below1080 ? 0 : 10 }}
            barCategoryGap={1}
            data={chartData}
            height="100%"
          >
            <XAxis
              tickLine={false}
              axisLine={false}
              interval="preserveEnd"
              minTickGap={80}
              tickMargin={14}
              tickFormatter={(tick) => toNiceDate(tick)}
              dataKey="date"
              tick={{ fill: textColor }}
              type={'number'}
              domain={['dataMin', 'dataMax']}
            />
            <YAxis
              type="number"
              axisLine={false}
              tickMargin={16}
              tickFormatter={(tick) => '$' + toK(tick)}
              tickLine={false}
              interval="preserveEnd"
              orientation="right"
              minTickGap={80}
              yAxisId={0}
              tick={{ fill: textColor }}
            />
            <Tooltip
              cursor={{ fill: color, opacity: 0.1 }}
              formatter={(val) => {
                if (setLatestValue && latestValue !== val) {
                  setLatestValue(val)
                }

                return formattedNum(val, true)
              }}
              labelFormatter={(label) => toNiceDateYear(label)}
              labelStyle={{ paddingTop: 4 }}
              contentStyle={{
                padding: '10px 14px',
                borderRadius: 10,
                borderColor: color,
                color: 'black',
              }}
              wrapperStyle={{ top: -70, left: -10 }}
            />
            <Bar
              type="monotone"
              name={'Volume'}
              dataKey={'dailyVolumeUSD'}
              fill={color}
              // opacity={'1'}
              yAxisId={0}
              stroke={color}
            />
          </BarChart>
        </ResponsiveContainer>
      )}

      {chartFilter === CHART_VIEW.PRICE && (
        <iframe
          id="dextswap-aggregator-widget"
          title="DEXTswap Aggregator"
          width="100%"
          height="100%"
          src={`https://www.dextools.io/widget-chart/en/polygon/pe-light/${address}?theme=light`}
          style={{ border: 'none' }}
        />
      )}
    </ChartWrapper>
  )
}

export default PairChart
